import { useState } from "react";
import { Button, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { signIn, parseErr } from "../../api";
import { Loader } from "../../components/Loader";
import validator from "validator";
import cls from "classnames";

export const SignIn = () => {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
    isValid: {
      email: false,
      password: false,
    },
  });

  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const onSignIn = async () => {
    setLoading(true);
    setErrMsg(null);
    try {
      const response = await signIn(userInput);
      // console.log(response);
      const { tokens, user } = response;
      const { isEmailVerified } = user;
      if (isEmailVerified) {
        // console.log(isEmailVerified);
        const accessToken = tokens.access.token;
        localStorage.setItem("token", accessToken);
        navigate("/", { replace: true });
      } else {
        navigate("/verify-email", { replace: true });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response) {
        const message = parseErr(err.response);
        console.log({ message });
        if (message) setErrMsg(message);
      }
    }
  };

  const onInputChangeHandler = (e) => {
    const { value, id } = e.target;
    setUserInput((prevState) => ({
      ...prevState,
      [id]: value,
      isValid: {
        ...prevState.isValid,
        [id]:
          id === "email"
            ? validator.isEmail(value)
            : validator.isLength(value, { min: 8 }),
      },
    }));
  };
  const { isValid } = userInput;
  const { email: validEmail, password: validPass } = isValid;
  return (
    <div className="auth-container">
      <div className="title">
        <h2>_sign in</h2>
      </div>
      <div className="input-wrapper">
        <Input
          id="email"
          onChange={onInputChangeHandler}
          className={cls("text-input", validEmail && "valid")}
          placeholder="enter email address"
        />
      </div>
      <div className="input-wrapper">
        <Input
          id="password"
          onChange={onInputChangeHandler}
          className={cls("text-input", validPass && "valid")}
          placeholder="password"
          type="password"
        />
      </div>
      <div className="err-container">
        {errMsg && <div className="err-msg">{errMsg}</div>}
      </div>
      <div className={cls("auth-actions", "align-items-start")}>
        {loading ? (
          <Loader loaderText="Signing In" />
        ) : (
          <Button
            disabled={!validPass || !validEmail}
            outline
            onClick={onSignIn}
            className="auth-button"
          >
            Sign in
          </Button>
        )}
        <div className={cls("auth-existing")}>
          <span>Don't have an account? </span>
          <span
            onClick={() => {
              navigate("/register");
            }}
            className="action"
          >
            Register here
          </span>
          <div className="mt-3">
            <span
              onClick={() => {
                navigate("/forgotpassword");
              }}
              className="action"
            >
              Forgot password?
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
