export const API_URL = process.env.REACT_APP_API_URL;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const chainConfig = {
  137: "Polygon Mainnet",
  80001: "Mumbai Testnet",
};

const chainHexConfig = {
  137: "0x89",
  80001: "0x13881",
};

const chainDetailConfig = {
  137: {
    chainId: "0x89",
    chainName: "Polygon Mainnet",
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://www.polygonscan.com"],
  },
  80001: {
    chainId: "0x13881",
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    chainName: "Polygon Testnet",
    nativeCurrency: {
      name: "tMATIC",
      symbol: "tMATIC",
      decimals: 18,
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
};

export const CHAIN_NAME = chainConfig[CHAIN_ID];

export const CHAIN_HEX = chainHexConfig[CHAIN_ID];

export const CHAIN_DETAILS = chainDetailConfig[CHAIN_ID];

export const IS_PROD = process.env.REACT_APP_IS_PROD;

export const ERC_1155_ADDRESS = process.env.REACT_APP_ERC_1155_ADDRESS;
