import { useEffect, useState, useCallback, forwardRef } from "react";
import { useWeb3React } from "@web3-react/core";
import useEagerConnect from "../hooks/useEagerConnect";
import { useInactiveListener } from "../hooks/useInactiveListener";
import { injected } from "../blockchain/connectors";
import { getErrorMessage } from "../blockchain";
import { useMintContract } from "../hooks/useMintContract";
import { CHAIN_HEX, CHAIN_DETAILS } from "../constant";
import { BigNumber } from "@ethersproject/bignumber";
// console.log(CHAIN_DETAILS);
const WithWeb3 = (Component) => {
  const ReallySmartComponent = forwardRef((props, ref) => {
    const triedEager = useEagerConnect();
    const { activate, account, connector, library } = useWeb3React();
    const { mintNFT, isAlreadyOwner, loading } = useMintContract();
    const [maticRecived, setHasMatic] = useState(false);
    // const [loading, setLoading] = useState(false);
    // console.log(isAlreadyOwner);
    const tryAddChain = async (provider) => {
      try {
        await provider.request({
          id: 1,
          jsonrpc: "2.0",
          method: "wallet_addEthereumChain",
          params: [CHAIN_DETAILS],
        });
      } catch (err) {
        console.log({ addErr: err });
      }
    };

    const tryChangeChain = useCallback(
      async (provider) => {
        try {
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: CHAIN_HEX }],
          });
          await activate(injected, undefined, true);
        } catch (err) {
          if (err.code === 4902) {
            await tryAddChain(provider);
          }
        }
      },
      [activate]
    );

    const checkCurrentChain = useCallback(async () => {
      try {
        const provider = await injected.getProvider();
        const chainIdHex = await provider.request({ method: "eth_chainId" });
        if (chainIdHex !== CHAIN_HEX) {
          await tryChangeChain(provider);
        }
      } catch (err) {
        console.log({ checkChain: err });
      }
    }, [tryChangeChain]);

    useEffect(() => {
      checkCurrentChain();
    }, [checkCurrentChain]);

    const connectWallet = useCallback(async () => {
      try {
        await activate(injected, undefined, true);
      } catch (err) {
        console.log(err);
        getErrorMessage(err);
        checkCurrentChain();
      }
    }, [activate, checkCurrentChain]);

    const [activatingConnector, setActivatingConnector] = useState();
    // console.log(library.provider);

    const hasMaticBalance = useCallback(async () => {
      // const provider = await injected.getProvider();
      // console.log(provider);
      const maticBalance = await library.getBalance(account);
      console.log(maticBalance);
      // return maticBalance.toString() != 0;
    }, [account, library]);

    const interval = setInterval(async () => {
      if (library) {
        const maticBalance = await library.getBalance(account);
        const zeroInBigNumber = BigNumber.from(0);
        setHasMatic(maticBalance.gt(zeroInBigNumber));
        // setHasMatic(maticBalance.div(10 ** 18) > 0);
      }
    }, 5000);

    if (maticRecived) clearInterval(interval);

    useEffect(() => {
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
      }
    }, [activatingConnector, connector]);

    useInactiveListener(!triedEager || !!activatingConnector);

    return (
      <Component
        {...props}
        account={account}
        connectWallet={connectWallet}
        ref={ref}
        library={library}
        mintNFT={mintNFT}
        fetchingFromChain={loading}
        isAlreadyOwner={isAlreadyOwner}
        hasMaticBalance={hasMaticBalance}
        maticRecived={maticRecived}
        // triedEager={triedEager}
      />
    );
  });
  return ReallySmartComponent;
};

export default WithWeb3;
