import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URI;
const _api = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  //   headers: { "Access-Control-Allow-Origin": "*" },
});

export const parseErr = (response) => {
  if (response.status === 200) return response.data;
  else return response.data.message;
};

const signIn = async ({ email, password }) => {
  const response = await _api.post("auth/signin", { email, password });
  return response.data;
};

const register = async ({ name, email, password }) => {
  const response = await _api.post("auth/register", { name, email, password });
  return response.data;
};

const sendVerificationEmail = async () => {
  const token = localStorage.getItem("token");
  const response = await axios.post(
    `${baseUrl}auth/send-verification-email`,
    null,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const verifyUserEmailToken = async (token) => {
  const response = await axios.post(`${baseUrl}auth/verify-email`, null, {
    params: {
      token,
    },
  });

  return response.data;
};

const getUserData = async () => {
  const token = localStorage.getItem("token");

  const response = await axios.post(`${baseUrl}me`, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const updateEthAddress = async (ethAddress) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(
    `${baseUrl}update-eth-address`,
    { ethAddress },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const sendResetPasswordLink = async (email) => {
  const response = await axios.post(`${baseUrl}auth/forgot-password`, {
    email,
  });
  return response.data;
};

const resetUserPassword = async (password, token) => {
  const response = await axios.post(
    `${baseUrl}auth/reset-password`,
    { password },
    { params: { token } }
  );
  return response.data;
};

const sendPostMintMail = async (openseaLink) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(
    `${baseUrl}send-post-mint-mail`,
    {
      openseaLink,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export {
  signIn,
  register,
  getUserData,
  updateEthAddress,
  sendVerificationEmail,
  verifyUserEmailToken,
  sendResetPasswordLink,
  resetUserPassword,
  sendPostMintMail,
};
