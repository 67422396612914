import { UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
// import { CHAIN_NAME } from "../constant";

// Handle errors for all connectors here
export const getErrorMessage = (error) => {
  if (error instanceof NoEthereumProviderError) {
    window.location.reload();
    return;
  } else if (error instanceof UnsupportedChainIdError) {
    console.error(error);
    return;
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    console.error(error);
    return;
  } else {
    console.error(error);
    return;
  }
};
