import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="notfound-wrapper">
      <img src="/404.svg" alt="" />
      <div className="back-home">
        <Button
          onClick={() => {
            navigate("/");
          }}
          outline
          className="back-home-button"
        >
          take me back home
        </Button>
      </div>
      <div className="not-found-text">
        hyperinteractive GmbH <br /> Am Sandtorkai 56 <br /> 20457 Hamburg{" "}
        <br /> D – Germany
      </div>
    </div>
  );
};
