import { useState, useCallback, useEffect } from "react";
import { Button } from "reactstrap";
import { getUserData } from "../../api";
import { Loader } from "../../components/Loader";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getUserData();
      if (res) {
        const { isEmailVerified } = res;
        if (!isEmailVerified) {
          localStorage.removeItem("token");
          navigate("/verify-email");
        } else {
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      localStorage.removeItem("token");
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (loading)
    return (
      <div className="empty-state">
        <Loader />
      </div>
    );
  return (
    <div className="home-wrapper">
      <div className="title">
        <h1>dear friends</h1>
        <h1 className="second-line-h1">and partners,</h1>
      </div>
      <div className="opener">
        <div>
          <p>
            It’s been an adventurous year, full of exciting projects, challenges
            but also great successes and we would hereby like to say thank you
            for accompanying us, for the good cooperation and your trust. At the
            end of the year, Hyperinteractive will turn 5 and we would like for
            you to celebrate with us, just in time for the holidays.
          </p>
          <p>
            We have kept our innovative spirit and generated NFT art with our
            signature _ The underscore has been a part of our journey from the
            very beginning. It is a link to the future and a placeholder for
            what is yet to come.
          </p>
          <p>
            We very much look forward to creating that future, together with you
            in 2022.
          </p>
          <p>
            Get inspired and claim one of only 150 NFTs – created exclusively
            for you as a gift – our closest partners and friends.
          </p>
          {/* <p>
            P.S. Naturally no gas fees will be billed, as it is a gift from us.
          </p> */}
        </div>
        <div className="home-btn-container">
          <Button
            onClick={() => {
              navigate("/mint");
            }}
            outline
            className="normal-button"
          >
            Start Process
          </Button>
        </div>
      </div>
    </div>
  );
};
