import useContract from "./useContract";
import { ERC_1155 } from "../blockchain";
import { useWeb3React } from "@web3-react/core";
import { useMemo, useCallback, useEffect, useState } from "react";
import { ERC_1155_ADDRESS } from "../constant";
// import { BigNumber } from "@ethersproject/bignumber";

// import { ethers } from "ethers";

export function useMintContract() {
  const { account } = useWeb3React();

  const mintContract = useContract(ERC_1155_ADDRESS, ERC_1155.abi);
  const [loading, setLoading] = useState(false);
  const [isAlreadyOwner, setOwnership] = useState(false);

  // console.log({ mintContract });

  const checkOwnedBalance = useCallback(async () => {
    setLoading(true);
    try {
      const balance = await mintContract.balanceOf(account, 1);
      if (balance.toNumber() > 0) setOwnership(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [account, mintContract]);

  const mintNFT = useCallback(async () => {
    const mintTRX = await mintContract.mintNFT(1);
    await mintTRX.wait();
  }, [mintContract]);

  useEffect(() => {
    if (account) checkOwnedBalance();
  }, [account, checkOwnedBalance]);

  return useMemo(() => {
    return { mintNFT, checkOwnedBalance, isAlreadyOwner, loading };
  }, [checkOwnedBalance, isAlreadyOwner, loading, mintNFT]);
}
