/* eslint-disable react/jsx-no-target-blank */
import cls from "classnames";
export const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-text">
        <div>© 2021_hyperinteractive. All rights reserved.</div>
        <div className="d-flex">
          <a
            href="https://www.hyperinteractive.de/data-policy"
            referrerPolicy="no-referrer"
            target="_blank"
            className={cls("footer-link", "mr188")}
          >
            privacy policy
          </a>
          <a
            href="https://www.hyperinteractive.de/imprint"
            referrerPolicy="no-referrer"
            target="_blank"
            className="footer-link"
          >
            imprint
          </a>
        </div>
      </div>
    </div>
  );
};
