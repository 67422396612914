import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { verifyUserEmailToken } from "../../api";

export const VerifyEmail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { token } = params;
  const [verifying] = useState(token ? true : false);

  const verifyEmail = useCallback(async () => {
    try {
      await verifyUserEmailToken(token);
      navigate("/signin");
    } catch (err) {
      console.log(err);
      // navigate("/signin");
    }
  }, [navigate, token]);

  useEffect(() => {
    if (token) verifyEmail();
  }, [token, verifyEmail]);

  if (verifying)
    return (
      <div className="empty-state">
        <Loader loaderText="Verifying" />
      </div>
    );
  return (
    <div className="auth-container">
      <div className="title">
        <h2>_check your inbox</h2>
      </div>
      <div className="forgotpass-title">
        If the e-mail hasn't arrived within 5 minutes, please check your spam,
        bulk or junk mail folder to locate it.
      </div>
    </div>
  );
};
