import { Collapse } from "reactstrap";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import classnames from "classnames";

const faq_list = [
  {
    ques: "What is an NFT?",
    ans: (
      <React.Fragment>
        <p>
          An NFT, or non-fungible token, is a unique, identifiable digital asset
          stored on the blockchain. An NFT could be a piece of digital artwork,
          a collectible, or even a digital representation of a real-life
          physical asset. Ownership of an NFT is easily and uniquely verifiable
          due to its public listing on the blockchain.
        </p>
      </React.Fragment>
    ),
  },
  {
    ques: "Why are we doing this?",
    ans: (
      <React.Fragment>
        <p>
          To keep our inventive spirit alive and dabble in the world of NFTs and
          Metaverses. This is a hot topic and we don’t want to miss out. As
          usual, you can find us on the tech-playground and we would hereby like
          to share our sand-castle with you, so you can also call it yours. We
          believe in sharing our knowledge. Only when we all understand the
          potential of a new technology, is it possible to build a great future
          in tech. The best way to understand something is to dive right in, so
          here we go! Hope you love your underscore.
        </p>
      </React.Fragment>
    ),
  },
  {
    ques: "What does it mean to “mint” an NFT?",
    ans: (
      <React.Fragment>
        <p>
          Minting refers to the process of tokenizing a digital file, or a
          digital piece of art and publishing it on the blockchain. Once an NFT
          is minted, you can verify ownership, buy, sell, and trade the NFT.
        </p>
      </React.Fragment>
    ),
  },
  {
    ques: "How do I set up a MetaMask wallet?",
    ans: (
      <React.Fragment>
        <p>
          Please refer{" "}
          <a
            href="https://medium.com/@hyperinteractive/install-metamask-and-set-up-polygon-network-18080169f5de"
            target="_blank"
            rel="noreferrer"
            className="muted"
          >
            this article
          </a>{" "}
          to learn how to set up your MetaMask wallet.
        </p>
      </React.Fragment>
    ),
  },
  {
    ques: "What happens if I lose access to my Ethereum wallet/address?",
    ans: (
      <React.Fragment>
        <p>
          The best way to prevent a lost wallet or wallet address is to make
          numerous physical copies of your seed phrase (“Secret Recovery
          Phrase”) and to store these copies in places you know and trust.
          Unfortunately, not a lot can be done if you’ve lost every copy of your
          wallet seed phrase, so please make sure to keep it safe. Never share
          your wallet seed phrase with anyone.
        </p>
      </React.Fragment>
    ),
  },
  {
    ques: "What is “GAS”?",
    ans: (
      <React.Fragment>
        <p>
          “Gas” on Blockchain networks is equivalent to the transaction fees
          associated with credit card transactions. To conduct a transaction on
          the blockchain network, you need to spend some Gas fees, which are
          paid in the native token of the blockchain - either ETH or MATIC.
        </p>
      </React.Fragment>
    ),
  },
  {
    ques: "What is the Polygon network?",
    ans: (
      <React.Fragment>
        <p>
          Our NFTs have been deployed on the Polygon network, an Ethereum
          sidechain, also considered a “green” blockchain. It is a Layer 2
          network which is faster, cost effective and even more scalable than
          it’s Layer 1 counterpart. Unlike proof-of-work, Polygon uses
          proof-of-stake validation, which ensures very low power consumption.
        </p>
      </React.Fragment>
    ),
  },
];
export const Card = ({ children, className, onClick }) => {
  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};

export const FAQ = () => {
  const [collapsed, setCollapsed] = useState(0);
  return (
    <div className="faq-container">
      <div className="faq-page-title">
        <h2>_faq</h2>
      </div>
      <div className="faq-wrapper">
        {faq_list.map((faq, idx) => (
          <Card
            key={idx}
            borderless
            className={classnames(
              "faq-collapse",
              "mx-auto",
              collapsed === idx ? "faq-collapsed" : "",
              idx === 0 && "top-bodder"
            )}
            onClick={() => setCollapsed(collapsed !== idx ? idx : null)}
          >
            <div
              style={{ cursor: "pointer" }}
              className="d-flex justify-content-between align-items-center"
            >
              <div
                className={classnames(
                  "collapse-header-title",
                  "d-flex align-items-center justify-content-between"
                )}
              >
                {faq.ques}{" "}
              </div>
              <div className={classnames("faq-toggle-button")}>
                <div
                  className={classnames("vert", idx === collapsed && "open")}
                ></div>
                <div
                  className={classnames("horz", idx === collapsed && "open")}
                ></div>
              </div>
            </div>
            <Collapse
              className={classnames("mt-2", "mobile-padding")}
              isOpen={idx === collapsed}
            >
              {faq.ans}
            </Collapse>
          </Card>
        ))}
      </div>
    </div>
  );
};
