/* eslint-disable react/jsx-no-target-blank */
import { useCallback, useEffect, useState } from "react";
import { Button, Progress } from "reactstrap";
import WithWeb3 from "../../hoc/withWeb3";
import cls from "classnames";
import { getUserData, updateEthAddress } from "../../api";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { Tooltip } from "reactstrap";
import { sendPostMintMail } from "../../api";
// import { saveAs } from "file-saver";
// import ipfs from "js-ipfs";

const StepOne = ({ onConnectWallet }) => {
  return (
    <div className="instructions-container">
      <div className="instructions">
        Here is a{" "}
        <a
          target="_blank"
          rel="norefferer"
          className="muted"
          href="https://medium.com/@hyperinteractive/install-metamask-and-set-up-polygon-network-18080169f5de"
        >
          step by step guide
        </a>
        . It will take 1-2 minutes to complete the setup. When you have the
        MetaMask browser extension installed and an account created, please come
        back to this page and <span className="muted">Connect to MetaMask</span>
        .
      </div>
      <div className="instructions">
        You will be asked to add Polygon network, please accept and allow
        MetaMask to switch network. After the switch, please press{" "}
        <span className="muted">Connect to MetaMask</span> once again.
      </div>
      <div className="instructions">
        You can skip the above steps if you already have a MetaMask account and
        browser extension installed.
      </div>
      <div className="instructions">Ready?</div>

      <Button onClick={onConnectWallet} outline className="outlined-button">
        Connect to MetaMask
      </Button>
    </div>
  );
};

const StepTwo = ({ account, maticSent, updateStep }) => {
  const [loading, setLoading] = useState(true);
  const updateUserEthAddress = useCallback(async (ethAddress) => {
    setLoading(true);
    try {
      await updateEthAddress(ethAddress);
      // console.log(res);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (account && maticSent === false) updateUserEthAddress(account);
    return () => controller.abort();
  }, [account, maticSent, updateUserEthAddress]);
  return (
    <div className="instructions-container">
      <div className="instructions">
        Hang in there! We are sending the MATIC token to your wallet. It may
        take upto one minute.
      </div>
      <div className="instructions">
        It will be used as GAS fees to mint the NFT in the next step. To
        understand these acronyms, read our FAQ.
      </div>
      <div className="instructions">
        P.S. Naturally no gas fees will be billed, as it is a gift from us.
      </div>

      {loading ? (
        <Loader loaderText="Processing" />
      ) : (
        <Button
          onClick={() => {
            updateStep(3);
          }}
          outline
          className="outlined-button"
        >
          Next
        </Button>
      )}
    </div>
  );
};

const StepThree = ({ onGetNftClick, inProgress, maticRecived }) => {
  return (
    <div className="mint-component">
      <div className="img-container">
        <img src="/Placeholder.svg" alt="" />
      </div>
      <div className="mint-text">
        <p>Ready?</p>
        {/* <p>
          P.S. Naturally no gas fees will be billed, as it is a gift from us.
        </p> */}
        {inProgress ? (
          <Loader loaderText="Minting, Please Wait" />
        ) : maticRecived ? (
          <Button onClick={onGetNftClick} outline className="outlined-button">
            Claim NFT
          </Button>
        ) : (
          <Loader loaderText="Checking Balance" />
        )}
      </div>
    </div>
  );
};

const StepFour = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const onCopyLink = () => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard
      .writeText("https://opensea.io/collection/hyperinteractive")
      .then(
        function () {
          // console.log("Async: Copying to clipboard was successful!");
          setTooltipOpen(true);
          const timeOut = setTimeout(() => {
            setTooltipOpen(false);
            clearTimeout(timeOut);
          }, 2000);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
  };

  const onDownloadNFT = async () => {
    const newLink = document.createElement("a");
    newLink.href =
      "https://static.xmas.hyperinteractive.de/hyperinteractive_underscore.mp4";
    newLink.target = "_blank";
    newLink.click();
    fetch(
      "https://static.xmas.hyperinteractive.de/hyperinteractive_underscore.mp4"
    ).then((response) => {
      response.blob().then((blob) => {
        const a = document.createElement("a");
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = `Hyperinteractive_Underscore NFT.mp4`;
        a.click();
      });
    });
  };
  return (
    <div className="mint-component">
      <div className="img-container">
        <video
          autoPlay
          // controls
          muted
          loop
          playsInline
          className="img-container"
        >
          <source
            src="https://static.xmas.hyperinteractive.de/hyperinteractive_underscore.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="mint-text">
        <p>Congratulations!</p>
        <p>
          We have kept our innovative spirit and generated NFT art with our
          signature _
        </p>
        <p>
          The underscore has been a part of our journey from the very beginning.
          It is a link to the future and a placeholder for what is yet to come.
        </p>
        <p>We wish you happy holidays and a wonderful new year.</p>
        <p>
          Warmest regards,
          <br />
          Team Hyperinteractive
        </p>
        <div className="btn-cont">
          <Button outline className="outlined-button">
            <a
              href="https://opensea.io/collection/hyperinteractive"
              target="_blank"
              rel="noopener"
            >
              View on Opensea
            </a>
          </Button>
        </div>
        <div className="share-nft">
          <div className={cls("share-row", "mr25")}>
            <div className="share-title">_download</div>
            <div className="share-action">
              <span onClick={onDownloadNFT}>download NFT</span>
            </div>
          </div>
          <div className="share-row">
            <div className="share-title">_share</div>
            <div className={cls("d-flex", "share-action")}>
              {/* <span className="mr20">ig</span> */}
              <span className="mr20">
                <a
                  href="https://www.facebook.com/dialog/share?app_id=972121560399780&display=popup&href=https://opensea.io/collection/hyperinteractive&quote=Just minted Hyperinteractive’s ‘Underscore’ NFT. Check it out here:"
                  target="_blank"
                  rel="noopener"
                >
                  fb
                </a>
              </span>
              <span className="mr20">
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.linkedin.com/shareArticle?mini=true&url=https://opensea.io/collection/hyperinteractive&title=Just minted Hyperinteractive’s ‘Underscore’ NFT. Check it out here:"
                >
                  li
                </a>
              </span>
              <span onClick={onCopyLink} id="copy-tooltip">
                copy link
              </span>
              <Tooltip
                placement="right"
                isOpen={tooltipOpen}
                target="copy-tooltip"
                className="copy-tooltip"
              >
                Link Copied
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const stepsConfig = {
  1: {
    title: "setup metamask and add polygon network",
    progress: 25,
  },
  2: {
    title: "taking care of the GAS fees",
    progress: 50,
  },
  3: {
    title: "get your NFT holiday greeting",
    progress: 100,
  },
  4: {
    title: "",
    progress: 100,
  },
};

const Mint = (props) => {
  const {
    connectWallet,
    account,
    mintNFT,
    isAlreadyOwner,
    fetchingFromChain,
    maticRecived,
  } = props;
  const navigate = useNavigate();
  const [maticAlreadySent, setMaticSentStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [inProgress, setMintingState] = useState(false);
  const [mintingComplete, setMintingComplete] = useState(false);
  const [alreadyMinted, setAlreadyMinted] = useState(false);

  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getUserData();
      if (res) {
        const { maticSent, nftMinted, isEmailVerified } = res;
        if (!isEmailVerified) {
          localStorage.removeItem("token");
          navigate("/verify-email");
          return;
        }
        setMaticSentStatus(maticSent);
        setAlreadyMinted(nftMinted);
        setLoading(false);
      }
    } catch (err) {
      localStorage.removeItem("token");
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // const checkBalance = useCallback(async () => {
  //   const balance = await checkOwnedBalance();
  //   console.log(balance);
  // }, [checkOwnedBalance]);

  // useEffect(() => {
  //   if (account) checkBalance();
  // }, [account, checkBalance]);

  useEffect(() => {
    if (!loading && account && !fetchingFromChain) {
      if (isAlreadyOwner || alreadyMinted) {
        setCurrentStep(4);
        setMintingComplete(true);
      } else if (maticAlreadySent) setCurrentStep(3);
      else setCurrentStep(2);
    }
    // setCurrentStep(3);
  }, [
    account,
    alreadyMinted,
    fetchingFromChain,
    isAlreadyOwner,
    loading,
    maticAlreadySent,
  ]);

  const currentStepConfig = stepsConfig[currentStep];
  const { title, progress } = currentStepConfig;

  const onConnectWallet = useCallback(async () => {
    try {
      await connectWallet();
      if (account) setCurrentStep(2);
    } catch (err) {
      console.log(err);
    }
  }, [account, connectWallet]);

  const onGetNftClick = useCallback(async () => {
    setMintingState(true);
    try {
      await mintNFT();
      await sendPostMintMail("https://opensea.io/collection/hyperinteractive");
      setMintingComplete(true);
      setCurrentStep(4);
    } catch (err) {
      console.log(err);
    }
  }, [mintNFT]);

  const getComponent = useCallback(() => {
    switch (currentStep) {
      case 1:
        return <StepOne onConnectWallet={onConnectWallet} />;
      case 2:
        return (
          <StepTwo
            account={account}
            maticSent={maticAlreadySent}
            updateStep={setCurrentStep}
          />
        );
      case 3:
        return (
          <StepThree
            maticRecived={maticRecived}
            inProgress={inProgress}
            onGetNftClick={onGetNftClick}
          />
        );
      case 4:
        return <StepFour />;
      default:
        return null;
    }
  }, [
    account,
    currentStep,
    inProgress,
    maticAlreadySent,
    maticRecived,
    onConnectWallet,
    onGetNftClick,
  ]);

  const children = getComponent();
  if (loading || fetchingFromChain)
    return (
      <div className="empty-state">
        <Loader />
      </div>
    );
  return (
    <div className="padded-container">
      <div className="mint-wrapper">
        <div className="title">
          {mintingComplete ? (
            <h2>_your NFT h_ has been minted!</h2>
          ) : (
            <h2>
              _step {currentStep}: {title}
            </h2>
          )}
        </div>
        {!mintingComplete && (
          <div className="stepper-container">
            <Progress value={progress} className="progress" />
            <div className="steps">
              <span>Step 1</span>
              <span>Step 2</span>
              <span>Step 3</span>
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default WithWeb3(Mint);
