import { Button, Input } from "reactstrap";
import { useState } from "react";
import validator from "validator";
import cls from "classnames";
import { useNavigate } from "react-router-dom";
import { sendResetPasswordLink } from "../../api";
import { Loader } from "../../components/Loader";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    email: "",
    isValid: {
      email: false,
    },
  });

  const onInputChangeHandler = (e) => {
    const { value, id } = e.target;
    setUserInput((prevState) => ({
      ...prevState,
      [id]: value,
      isValid: {
        ...prevState.isValid,
        [id]: validator.isEmail(value),
      },
    }));
  };

  const onResetPassword = async () => {
    setLoading(true);
    try {
      const { email } = userInput;
      await sendResetPasswordLink(email);
      navigate("/reset-password");
      // console.log(response);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const { isValid } = userInput;
  const { email: validEmail } = isValid;
  return (
    <div className="auth-container">
      <div className="title">
        <h2>_forgot your password?</h2>
      </div>
      <div className="forgotpass-title">
        Don't worry it happens to the best of us.
      </div>
      <div className="input-wrapper">
        <Input
          id="email"
          onChange={onInputChangeHandler}
          className={cls("text-input", validEmail && "valid")}
          placeholder="enter email address"
        />
      </div>
      <div className="auth-actions">
        {loading ? (
          <Loader loaderText="Please wait" />
        ) : (
          <Button
            disabled={!validEmail}
            outline
            onClick={onResetPassword}
            className={"auth-button"}
          >
            Reset password
          </Button>
        )}
        <div className="auth-existing">
          <span>Don't have an account? </span>
          <span
            onClick={() => {
              navigate("/register");
            }}
            className="action"
          >
            Register here
          </span>
        </div>
      </div>
    </div>
  );
};
