import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
// import { toast } from "react-toastify";
// import { TOAST_ID_WRONG_CHAIN } from "../blockchain";
// import { CHAIN_ID } from "../constant";

export function useInactiveListener(suppress = false) {
  const { active, error } = useWeb3React();
  useEffect(() => {
    const { ethereum } = window;
    if (ethereum && ethereum.on && active && !error && !suppress) {
      const handleChainChanged = (chainId) => {
        console.log("chainChanged", chainId);
        // callback();
      };

      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        // callback();
      };

      // const handleNetworkChanged = (networkId) => {
      //   console.log("networkChanged", networkId);
      //   console.log("HHHHHH", typeof networkId);
      //   if (networkId === parseInt(CHAIN_ID))
      //     toast.dismiss(TOAST_ID_WRONG_CHAIN);
      //   callback();
      // };

      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      // ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          // ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }

    // return () => {};
  }, [active, error, suppress]);
}
