import { useState } from "react";
import { Button, Input } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { resetUserPassword } from "../../api";
import validator from "validator";
import cls from "classnames";

export const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { token } = params;
  const [userInput, setUserInput] = useState({
    password: "",
    confirm: "",
    isValid: {
      password: false,
      confirm: false,
    },
  });

  const onInputChangeHandler = (e) => {
    const { value, id } = e.target;
    setUserInput((prevState) => ({
      ...prevState,
      [id]: value,
      isValid: {
        ...prevState.isValid,
        [id]: validator.isLength(value, { min: 8 }),
      },
    }));
  };

  const onSetPassword = async () => {
    setLoading(true);
    try {
      const { password } = userInput;
      await resetUserPassword(password, token);
      navigate("/signin");
      //   console.log(response);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  //   const [verifying, setVerifyState] = useState(token ? true : false);

  //   const verifyEmail = useCallback(async () => {
  //     try {
  //       await verifyUserEmailToken(token);
  //       navigate("/signin");
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }, [navigate, token]);

  //   useEffect(() => {
  //     if (token) verifyEmail();
  //   }, [token, verifyEmail]);

  //   if (verifying)
  //     return (
  //       <div className="empty-state">
  //         <Loader loaderText="Verifying" />
  //       </div>
  //     );
  const { isValid, password, confirm } = userInput;
  const { confirm: validConfirm, password: validPass } = isValid;
  if (token)
    return (
      <div className="auth-container">
        <div className="title">
          <h2>_change password</h2>
        </div>
        <div className="forgotpass-title">
          Don't worry it happens to the best of us.
        </div>
        <div className="input-wrapper">
          <Input
            id="password"
            onChange={onInputChangeHandler}
            className={cls(
              "text-input",
              validPass && password === confirm && "valid"
            )}
            placeholder="create password (minimum 8 characters)"
            type="password"
          />
        </div>
        <div className="input-wrapper">
          <Input
            id="confirm"
            onChange={onInputChangeHandler}
            className={cls(
              "text-input",
              validConfirm && password === confirm && "valid"
            )}
            placeholder="confirm password"
            type="password"
          />
        </div>
        <div className="auth-actions">
          {loading ? (
            <Loader loaderText="Resetting" />
          ) : (
            <Button
              disabled={!validConfirm || !validPass || password !== confirm}
              outline
              onClick={onSetPassword}
              className="auth-button"
            >
              Set Password
            </Button>
          )}
        </div>
      </div>
    );
  return (
    <div className="auth-container">
      <div className="title">
        <h2>_check your inbox</h2>
      </div>
      <div className="forgotpass-title">
        We just emailed you the password reset link. If the e-mail hasn't
        arrived within 5 minutes, please check your spam, bulk or junk mail
        folder to locate it.
      </div>
    </div>
  );
};
