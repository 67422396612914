import { useState } from "react";
import { Button, Input, Label } from "reactstrap";
import cls from "classnames";
import { useNavigate } from "react-router-dom";
import { register, parseErr, sendVerificationEmail } from "../../api";
import validator from "validator";
import { Loader } from "../../components/Loader";
//dumdum
export const Register = () => {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    password: "",
    check: false,
    isValid: {
      name: false,
      email: false,
      password: false,
      check: false,
    },
  });

  const [errMsg, setErrMsg] = useState(null);

  const [loading, setLoading] = useState(false);

  const onSignUp = async () => {
    setLoading(true);
    setErrMsg(null);
    try {
      const response = await register(userInput);
      const { tokens } = response;
      const accessToken = tokens.access.token;
      localStorage.setItem("token", accessToken);
      await sendVerificationEmail();
      navigate("/verify-email", { replace: true });
    } catch (err) {
      const errJ = err.toJSON();
      console.log({ errJ });
      setLoading(false);
      if (err.response) {
        const message = parseErr(err.response);
        console.log({ message });
        if (message) setErrMsg(message);
      }
    }
  };

  const onInputChangeHandler = (e) => {
    const { value, id, checked } = e.target;
    setUserInput((prevState) => ({
      ...prevState,
      [id]: id === "check" ? checked : value,
      isValid: {
        ...prevState.isValid,
        [id]:
          id === "email"
            ? validator.isEmail(value)
            : id === "check"
            ? checked === true
            : id === "name"
            ? !validator.isEmpty(value)
            : validator.isLength(value, { min: 8 }),
      },
    }));
  };
  const { check, isValid } = userInput;
  const {
    name: validName,
    email: validEmail,
    password: validPass,
    check: checked,
  } = isValid;
  return (
    <div className="auth-container">
      <div className="title">
        <h2>_register</h2>
      </div>
      <div className="input-wrapper">
        <Input
          id="name"
          onChange={onInputChangeHandler}
          className={cls("text-input", validName && "valid")}
          placeholder="enter your name"
        />
      </div>
      <div className="input-wrapper">
        <Input
          id="email"
          onChange={onInputChangeHandler}
          className={cls("text-input", validEmail && "valid")}
          placeholder="enter email address"
        />
      </div>
      <div className="input-wrapper">
        <Input
          id="password"
          onChange={onInputChangeHandler}
          className={cls("text-input", validPass && "valid")}
          placeholder="password (minimum 8 characters)"
          type="password"
        />
      </div>
      <Label className={cls("input-wrapper", "inline")}>
        <Input
          id="check"
          onChange={onInputChangeHandler}
          type="checkbox"
          className={cls("checkbox", check && "black-check")}
        />
        <div className="checkbox-label">
          I hereby acknowledge that all information of this event is for
          internal use only.
        </div>
      </Label>
      <div className="err-container">
        {errMsg && <div className="err-msg">{errMsg}</div>}
      </div>
      <div className="auth-actions">
        {loading ? (
          <Loader loaderText="Signing Up" />
        ) : (
          <Button
            disabled={!validName || !validEmail || !validPass || !checked}
            outline
            onClick={onSignUp}
            className={"auth-button"}
          >
            Sign up
          </Button>
        )}
        <div className={"auth-existing"}>
          <span>Already have an account? </span>
          <span
            onClick={() => {
              navigate("/signin");
            }}
            className="action"
          >
            Sign in
          </span>
        </div>
      </div>
    </div>
  );
};
