// import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import cls from "classnames";
import { useLocation } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoutes";
import { Register } from "./containers/Register";
import { SignIn } from "./containers/Signin";
import { ForgotPassword } from "./containers/ForgotPassword";
import { Home } from "./containers/Home";
import { FAQ } from "./containers/FAQ";
import { VerifyEmail } from "./containers/VerifyEmail";
import { ResetPassword } from "./containers/ResetPassword";
import { NotFound } from "./containers/NotFound";
import { Footer } from "./components/Footer";
import Mint from "./containers/Mint";
import { isMobile, isSafari, isIE } from "react-device-detect";

//Test
function App() {
  const location = useLocation();
  const validRoutes = [
    "/register",
    "/signin",
    "/forgotpassword",
    "/verify-email",
    "reset-password",
    "/mint",
    "/faq",
  ];

  const hideFooter =
    location.pathname === "/" || validRoutes.indexOf(location.pathname) === -1;
  // const [modalOpen, setModalState] = useState(false);
  // const [openIframe, setIframeState] = useState(false);
  // console.log(window.navigator);
  if (isIE || isSafari || isMobile)
    return (
      <div className="App">
        <div className="no-content">
          <div className="content">
            Desktop only experience.
            <br /> Supported on Chrome, Brave and Firefox.
          </div>
        </div>
      </div>
    );
  return (
    <div className="App">
      <div className={cls("main")}>
        <Navbar />
        <div className="content-container">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route path="/register" element={<Register />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/mint"
              element={
                <ProtectedRoute>
                  <Mint />
                </ProtectedRoute>
              }
            />
            <Route path="/faq" element={<FAQ />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        {!hideFooter && <Footer />}
      </div>
    </div>
  );
}

export default App;
