import cls from "classnames";
import { Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../blockchain";
import { getErrorMessage } from "../../blockchain";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useEagerConnect from "../../hooks/useEagerConnect";
// import { Modal, ModalBody } from "reactstrap";
// import { YoutubeIframe } from "../YouTubeIframe";

export const Navbar = (props) => {
  useEagerConnect();
  const navigate = useNavigate();
  const location = useLocation();
  const showLinksAndBtn =
    location.pathname === "/" ||
    location.pathname === "/mint" ||
    location.pathname === "/faq";
  const { activate, account, deactivate } = useWeb3React();

  const connectWallet = useCallback(async () => {
    try {
      await activate(injected, undefined, true);
      navigate("/mint");
    } catch (err) {
      console.log(err);
      getErrorMessage(err);
    }
  }, [activate, navigate]);

  const disconnectWallet = () => {
    localStorage.removeItem("token");
    deactivate();
    navigate("/signin");
  };

  return (
    <div className="navbar-wrapper">
      <div
        onClick={() => {
          navigate("/");
        }}
        className="navbar-logo"
      >
        <img src="/logo.svg" alt="" />
      </div>

      {showLinksAndBtn && (
        <div className="nav-links">
          {/* <div
            onClick={() => {
              setIframeState(true);
            }}
            className="nav-item"
          >
            Video Tutorial
          </div> */}
          <div className="nav-item">
            <a
              href="https://medium.com/@hyperinteractive/install-metamask-and-set-up-polygon-network-18080169f5de"
              target="_blank"
              rel="noreferrer"
            >
              Guide
            </a>
          </div>
          <div className="nav-item">
            <a href="/faq" target="_blank" rel="noreferrer">
              FAQ
            </a>
          </div>

          <Button
            onClick={account ? disconnectWallet : connectWallet}
            className={cls("normal-button")}
          >
            {account ? "DiSCONNECT Wallet" : "CONNECT WALLET"}
          </Button>
        </div>
      )}
      {/* {modalOpen && (
        <Modal className="custom-modal" isOpen={modalOpen}>
          <div className="modal-head">
            <h2>_about</h2>
            <div
              onClick={() => {
                setModalState(false);
              }}
              className="close-btn"
            >
              <img src="x-close.png" alt="" />
            </div>
          </div>
          <ModalBody className="modal-body">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam
              viverra mi, placerat laoreet velit metus, consectetur lorem
              imperdiet. Posuere volutpat adipiscing egestas sit. Viverra mi
              egestas risus facilisis a, nunc. Lectus quam nunc lobortis sit
              semper lorem libero sit tortor. Nulla nunc odio libero magna
              pellentesque egestas.
            </p>
            <p>
              {" "}
              Libero odio mi sapien, aliquet. Tempor tempus consequat dui odio
              arcu nibh ut vestibulum cras. Nisi quis gravida orci mauris.
              Eleifend diam imperdiet gravida natoque duis pulvinar a,
              convallis. Leo quis lectus malesuada leo. Tellus quam tortor
              lectus euismod lorem duis suscipit velit. Fermentum, viverra erat
              tempus fames iaculis adipiscing libero sed tempus.
            </p>
          </ModalBody>
        </Modal>
      )}
      {openIframe && (
        <YoutubeIframe isOpen={openIframe} setModalState={setIframeState} />
      )} */}
    </div>
  );
};
